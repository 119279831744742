import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const constantRoutes=[
  {
    path:'/',
    component: (resolve) => require(['@/views/index'], resolve),
    hidden: true,
    meta:{
      title:'主页'
    },
    redirect: '/home',
    children:[
      {
        path: '/home',
        component: (resolve) => require(['@/views/home'], resolve),
        hidden: true,
        meta:{
          title:'官网首页'
        },
        name: 'home',
      },
      {
        path: '/medical',
        component: (resolve) => require(['@/views/medical'], resolve),
        hidden: true,
        meta:{
          title:'医疗系统'
        },
        name: 'medical',
      },
      {
        path: '/media',
        component: (resolve) => require(['@/views/media'], resolve),
        hidden: true,
        meta:{
          title:'媒体报道'
        },
        name: 'media',
      },
      {
        path: '/aboutUs',
        component: (resolve) => require(['@/views/aboutUs'], resolve),
        hidden: true,
        meta:{
          title:'关于我们'
        },
        name: 'aboutUs',
      },
      {
        path: '/bidding',
        component: (resolve) => require(['@/views/bidding'], resolve),
        hidden: true,
        meta:{
          title:'招标公告'
        },
        name: 'bidding',
      },
      {
        path: '/latest',
        component: (resolve) => require(['@/views/latest'], resolve),
        hidden: true,
        meta:{
          title:'最新消息'
        },
        name: 'latest',
      },
      {
        path: '/details',
        component: (resolve) => require(['@/views/details'], resolve),
        hidden: true,
        meta:{
          title:'详情'
        },
        name: 'details',
      },
      {
        path: '/recruit',
        component: (resolve) => require(['@/views/recruit'], resolve),
        hidden: true,
        meta:{
          title:'职位详情'
        },
        name: 'recruit',
      }
    ]
  },
]
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default new Router({
  mode: 'history', // 去掉url中的#
  // base:'/h5share/',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
